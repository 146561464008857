import React, { FC, useEffect } from 'react'

import './assets/styles.sass'

interface WindowProps {
  title: string
  onClose?: () => void
}

// @ts-ignore
const Window: FC<WindowProps> = ({ title, children, onClose }) => {
  useEffect(() => {
    const handleDocumentKeydown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose!()
      }
    }

    if (onClose) {
      document.addEventListener('keydown', handleDocumentKeydown)
    }

    return () => {
      if (onClose) {
        document.removeEventListener('keydown', handleDocumentKeydown)
      }
    }
  }, [onClose])

  return (
    <div className="window">
      <div className="window__header">
        <div className="window__header-text">{title}</div>
        {onClose ? (
          <div className="window__header-buttons">
            <button
              className="window__button-close"
              onClick={onClose}
              aria-label="close"
            ></button>
          </div>
        ) : null}
      </div>
      <div className="window__content">{children}</div>
    </div>
  )
}

export default Window
