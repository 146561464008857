import React from 'react'
import { DesktopIcon } from './DesktopIcon'
import { useWindowManager } from '../WindowManagerProvider'

export const PictureWindowIcon: React.FC<{
  name: string
  src: string
  icon: React.FunctionComponent
  width?: string
  height?: string
  open?: boolean
}> = ({ name, src, icon, width, height, open = false }) => {
  const { toggleWindow } = useWindowManager()

  const IconComponent = icon

  return (
    <DesktopIcon onClick={() => toggleWindow(name, true)}>
      {/* @ts-ignore */}
      <IconComponent variant="32x32_4" />
      <div>{name}</div>
    </DesktopIcon>
  )
}
