import React, { useCallback, useState } from 'react'
import { Folder, Mshtml32540, MediaVideo } from '@react95/icons'
import { DesktopIcon } from './DesktopIcon'
import styled from 'styled-components'
import { IconLabel } from './IconLabel'
import { WindowFrame } from './WindowFrame'
import { RegularModal } from '../RegularModal'
import { PictureWindowIcon } from './PictureWindowIcon'

const FolderDiv = styled.div`
  display: flex;
  grid-template-columns: repeat(4, 1fr);
  flex-wrap: wrap;
`

// TODO why does this cause errors
// const PictureModal = styled(Modal)`
//   padding: 2px;
// `

export const Memories: React.FC<{ onClose(): void }> = ({ onClose }) => {
  const files = [
    {
      name: 'sox.jpg',
      src: '/assets/images/sox.jpg',
      icon: Mshtml32540
    },
    {
      name: 'outtakes.mov',
      src: '/assets/video/outtakes.mov',
      icon: MediaVideo
    }
  ]

  return (
    <>
      {/* @ts-ignore */}
      <RegularModal
        icon={<Folder variant="32x32_4" />}
        title="Good Times"
        closeModal={onClose}
        width="300"
        height="200"
        menu={[
          // @ts-ignore
          { name: 'File', list: [] },
          // @ts-ignore
          { name: 'Edit', list: [] },
          // @ts-ignore
          { name: 'Help', list: [] }
        ]}
      >
        <WindowFrame bg="white" boxShadow="in">
          <FolderDiv>
            {files.map((file) => {
              return (
                <PictureWindowIcon
                  key={file.name}
                  name={file.name}
                  src={file.src}
                  icon={file.icon}
                />
              )
            })}
          </FolderDiv>
        </WindowFrame>
      </RegularModal>
    </>
  )
}

export const MemoriesContainer: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const handleClose = useCallback(() => setIsOpen(false), [setIsOpen])

  return (
    <>
      <DesktopIcon onClick={() => setIsOpen(true)}>
        <Folder variant="32x32_4" />
        <IconLabel>Good Times</IconLabel>
      </DesktopIcon>
      {isOpen && <Memories onClose={handleClose} />}
    </>
  )
}
