import React, { createContext, useCallback, useContext, useState } from 'react'

export type WindowState = {
  [key: string]: boolean
}

export type WMContext = {
  windowState: WindowState
  toggleWindow: (name: string, open: boolean) => void
}

export const WindowManagerContext = createContext<WMContext>({
  windowState: {},
  toggleWindow: () => null
})

export const WindowManagerProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [windowState, setWindowState] = useState<WindowState>({})

  const toggleWindow = useCallback(
    (windowName: string, open: boolean) => {
      setWindowState((prev) => {
        return {
          ...prev,
          [windowName]: open
        }
      })
    },
    [setWindowState]
  )

  return (
    <WindowManagerContext.Provider value={{ windowState, toggleWindow }}>
      {children}
    </WindowManagerContext.Provider>
  )
}

export function useWindowManager() {
  return useContext(WindowManagerContext)
}
