import React from 'react'

import { Status } from 'minesweeper/types'
import aliveImg from './assets/images/smile.png'
import touchImg from './assets/images/sweating.png'
import deadImg from './assets/images/dead.png'
import winImg from './assets/images/cool.png'

import './assets/styles.sass'

interface StatusProps {
  status: Status
  onClick: () => void
}

type EmojiSrc = {
  [key in Status]: string
}

const emojiSrc: EmojiSrc = {
  alive: aliveImg,
  touch: touchImg,
  dead: deadImg,
  win: winImg
}

const EmojiStatus = ({ status, onClick }: StatusProps) => {
  return (
    <div className="emoji-status" onClick={onClick}>
      <img
        className="emoji-status__image"
        src={emojiSrc[status]}
        alt={`You are ${status}`}
      />
    </div>
  )
}

export default EmojiStatus
