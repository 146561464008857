import React from 'react'
import styled from 'styled-components'

const LinkContainer = styled.a`
  margin: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  width: fit-content;
  gap: 5px;
  flex-direction: column;
  cursor: pointer;
  text-decoration: none;

  & * {
    cursor: pointer;
  }
`

export const DesktopLink: React.FC<
  { children: React.ReactNode } & React.HTMLAttributes<HTMLAnchorElement>
> = ({ children, ...rest }) => {
  return <LinkContainer {...rest}>{children}</LinkContainer>
}
