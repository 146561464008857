import React from 'react'
import { Modal } from '@react95/core/dist'
import { MediaVideo, Mshtml32540, Winmine1 } from '@react95/icons'
import { WorldProvider } from './minesweeper/context/world'
import Menu from './minesweeper/components/Menu'
import Game from './minesweeper/components/Game'
import { useWindowManager } from './WindowManagerProvider'
import styled from 'styled-components'
import { PictureViewer } from './desktop/PictureViewer'
import { DatabaseWindow } from './windows/databases/DatabaseWindow'

const MinesweeperModal = styled(Modal)`
  padding: 2px;
  left: 50px;

  & :nth-child(2) {
    padding: 0;
  }
`

export const Windows: React.FC = () => {
  const { windowState, toggleWindow } = useWindowManager()

  return (
    <>
      {windowState['DB'] && (
        <DatabaseWindow onClose={() => toggleWindow('DB', false)} />
      )}

      {windowState['Minesweeper'] && (
        // @ts-ignore
        <MinesweeperModal
          icon={<Winmine1 variant="32x32_4" />}
          title="Minesweeper"
          closeModal={() => toggleWindow('Minesweeper', false)}
        >
          <WorldProvider>
            <Menu />
            <Game />
          </WorldProvider>
        </MinesweeperModal>
      )}

      {windowState['sox.jpg'] && (
        <PictureViewer
          // @ts-ignore shut up
          name="sox.jpg"
          // @ts-ignore shut up
          src="/assets/images/sox.jpg"
          // @ts-ignore shut up
          icon={<Mshtml32540 variant="32x32_4" />}
          onClose={() => toggleWindow('sox.jpg', false)}
          height="400px"
          width="300px"
        />
      )}

      {windowState['totm.jpg'] && (
        <PictureViewer
          // @ts-ignore shut up
          name="totm.jpg"
          // @ts-ignore shut up
          src="/assets/images/totm.jpg"
          // @ts-ignore shut up
          icon={<Mshtml32540 variant="32x32_4" />}
          onClose={() => toggleWindow('totm.jpg', false)}
          width="50%"
        />
      )}

      {windowState['outtakes.mov'] && (
        <Modal
          closeModal={() => toggleWindow('outtakes.mov', false)}
          title="outtakes.mov"
          icon={<MediaVideo variant="32x32_4" />}
        >
          <video width="320" src="/assets/video/outtakes.mov" controls></video>
        </Modal>
      )}
    </>
  )
}
