import LV1DB from './LV1.json'
import CS1DB from './CS1.json'
import AXEDB from './Axe.json'
import AUTOCOCKERDB from './Autococker.json'
import GEO4DB from './Geo4.json'
import LV1_6DB from './LV1_6.json'
import CS2DB from './CS2.json'
import LV2DB from './LV2.json'
import CS3DB from './CS3.json'
import EMEKDB from './Emek.json'
import ETHA3MDB from './Etha3M.json'
import { DatabaseWindowFieldMap } from './DatabaseWindow'
import { TwisterDatabase } from './utils'

type DBWindowList = {
  [key: string]: {
    fieldMap: DatabaseWindowFieldMap
    database: TwisterDatabase
    name?: string
  }
}
export const DB_WINDOWS: DBWindowList = {
  LV1: {
    fieldMap: new Map([
      ['serial', 'Serial #'],
      ['bpnum', 'BP #'],
      ['colorway', 'Colorway']
    ]),
    database: LV1DB
  },
  CS1: {
    fieldMap: new Map([
      ['serial', 'Serial #'],
      ['bpnum', 'BP #'],
      ['colorway', 'Colorway']
    ]),
    database: CS1DB
  },
  Axe: {
    fieldMap: new Map([
      ['bpnum', 'BP #'],
      ['colorway', 'Colorway']
    ]),
    database: AXEDB
  },
  Autococker: {
    fieldMap: new Map([
      ['bpnum', 'BP #'],
      ['edition', 'Edition'],
      ['colorway', 'Colorway']
    ]),
    database: AUTOCOCKERDB
  },
  Geo4: {
    fieldMap: new Map([
      ['serial', 'Serial #'],
      ['bpnum', 'BP #'],
      ['colorway', 'Colorway']
    ]),
    database: GEO4DB
  },
  'LV1.6': {
    fieldMap: new Map([
      ['serial', 'Serial #'],
      ['bpnum', 'BP #'],
      ['colorway', 'Colorway']
    ]),
    database: LV1_6DB
  },
  CS2: {
    fieldMap: new Map([
      ['serial', 'Serial #'],
      ['bpnum', 'BP #'],
      ['colorway', 'Colorway']
    ]),
    database: CS2DB
  },
  LV2: {
    fieldMap: new Map([
      ['serial', 'Serial #'],
      ['bpnum', 'BP #'],
      ['colorway', 'Colorway']
    ]),
    database: LV2DB
  },
  CS3: {
    fieldMap: new Map([
      ['serial', 'Serial #'],
      ['bpnum', 'BP #'],
      ['colorway', 'Colorway']
    ]),
    database: CS3DB
  },
  Emek: {
    fieldMap: new Map([
      ['serial', 'Serial #'],
      ['colorway', 'Colorway']
    ]),
    database: EMEKDB
  },
  Etha3M: {
    fieldMap: new Map([
      ['serial', 'Serial #'],
      ['colorway', 'Colorway']
    ]),
    database: ETHA3MDB
  }
}

export type DBWindowListType = keyof typeof DB_WINDOWS
