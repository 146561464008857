import React from 'react'
import styled from 'styled-components'

const IconContainer = styled.div`
  margin: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  width: fit-content;
  gap: 5px;
  flex-direction: column;
  cursor: pointer;

  & * {
    cursor: pointer;
  }
`

export const DesktopIcon: React.FC<
  { children: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>
> = ({ children, ...rest }) => {
  return <IconContainer {...rest}>{children}</IconContainer>
}
