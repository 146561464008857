import styled from 'styled-components'

export const DataTableContainer = styled.div`
  max-height: 500px;
  overflow-y: auto;
`

export const DataTable = styled.table`
  width: 400px;
  border-spacing: 0;

  th,
  td {
    text-align: left;
  }

  tr {
    background: radial-gradient(circle at bottom, black 1px, transparent 1.5px)
      repeat-x bottom;
    background-size: 5px 4px;
  }

  th {
    font-weight: bold;
  }

  tr:nth-child(odd) {
    background-color: #eee;
  }
  tr:nth-child(even) {
    background-color: #fff;
  }

  td {
    padding: 5px;
  }
`
