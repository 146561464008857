export type TwisterDatabaseEntry = {
  colorway: string
  bpnum?: string
  serial?: string
  edition?: string
}
export type TwisterDatabase = TwisterDatabaseEntry[]

// build list of colorways
export function generateColorwayList(db: TwisterDatabase) {
  const allColorways = db.map((e) => e.colorway)
  const colorwaySet = new Set(allColorways)
  const colorways = Array.from(colorwaySet).filter(Boolean)
  colorways.sort()
  return colorways
}
