import React, { useState } from 'react'

import { useWorldDispatch } from 'minesweeper/context/world'
import Modal from 'minesweeper/components/Modal'
import Help from 'minesweeper/components/Menu/Help'
import Settings from 'minesweeper/components/Menu/Settings'
import Window from 'minesweeper/components/Window'
import presets from 'minesweeper/context/lib/presets.json'

import './assets/styles.sass'

const Menu = () => {
  const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false)
  const [showHelpModal, setShowHelpModal] = useState<boolean>(false)
  const { createWorld } = useWorldDispatch()

  // @ts-ignore
  return (
    <>
      <div className="menu">
        <button
          className="menu__item"
          onClick={() => setShowSettingsModal(true)}
        >
          Settings
        </button>
        <button className="menu__item" onClick={() => setShowHelpModal(true)}>
          Help
        </button>
      </div>

      {showSettingsModal && (
        /* @ts-ignore */
        <Modal>
          {/* @ts-ignore */}
          <Window title="Setting" onClose={() => setShowSettingsModal(false)}>
            <Settings
              values={presets.easy}
              onSubmit={(preset) => {
                setShowSettingsModal(false)
                createWorld(preset)
              }}
              onCancel={() => setShowSettingsModal(false)}
            />
          </Window>
        </Modal>
      )}

      {showHelpModal && (
        // @ts-ignore
        <Modal>
          {/* @ts-ignore */}
          <Window title="About" onClose={() => setShowHelpModal(false)}>
            <Help />
          </Window>
        </Modal>
      )}
    </>
  )
}

export default Menu
