import React from 'react'
import { RegularModal } from '../RegularModal'
import { Mshtml32540 } from '@react95/icons'
import styled from 'styled-components'
import { Frame } from '@react95/core/dist'

const Picture = styled.img`
  max-height: 100%;
  max-width: 100%;
`

const PictureFrame = styled(Frame)`
  max-height: calc(100% - 35px);
  max-width: fit-content;
  align-self: center;
`

export const PictureViewer: React.FC<{
  name: string
  src: string
  onClose: () => void
  icon: string
  width?: string
  height?: string
}> = ({ name, src, onClose, width, height, icon }) => {
  // tODO maybe make the icon dynamic
  return (
    // @ts-ignore
    <RegularModal
      icon={icon ?? <Mshtml32540 variant="32x32_4" />}
      title={name}
      closeModal={onClose}
      menu={[
        // @ts-ignore
        { name: 'File', list: [] },
        // @ts-ignore
        { name: 'Edit', list: [] }
      ]}
      width={width}
      height={height}
    >
      <PictureFrame bg="white" boxShadow="in">
        <Picture src={src} alt={name} />
      </PictureFrame>
    </RegularModal>
  )
}
