import React from 'react'
import { RecycleBinContainer } from './RecycleBin'
import { MemoriesContainer } from './Memories'
import { Mshtml32540, Mshtml32549 } from '@react95/icons'
import { IconLabel } from './IconLabel'
import { DesktopLink } from './DesktopLink'
import styled from 'styled-components'
import { PictureWindowIcon } from './PictureWindowIcon'

const DesktopContainer = styled.div`
  display: grid;
  grid-auto-columns: max-content;
  align-content: start;
  justify-items: center;
`

export const DesktopView: React.FC = () => {
  return (
    <DesktopContainer>
      <RecycleBinContainer />
      <MemoriesContainer />

      <DesktopLink
        // @ts-ignore yes it does
        href="https://committedpaintball.com"
        target="_blank"
      >
        <Mshtml32549 variant="32x32_4" />
        <IconLabel>Shop</IconLabel>
      </DesktopLink>

      <PictureWindowIcon
        name="totm.jpg"
        src="/assets/images/totm.jpg"
        icon={Mshtml32540}
        width="50%"
        open
      />
    </DesktopContainer>
  )
}
