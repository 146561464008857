import { RegularModal } from '../../RegularModal'
import { TwisterIcon } from '../../TwisterIcon'
import { WindowFrame } from '../../desktop/WindowFrame'
import { DataTable, DataTableContainer } from '../../DataTable'
import React, { useMemo, useState } from 'react'
import { Dropdown } from '@react95/core/dist'
import { FilterContainer } from './FilterContainer'

import { generateColorwayList, TwisterDatabaseEntry } from './utils'
import { DB_WINDOWS, DBWindowListType } from './database_list'

export type DatabaseWindowFieldMap = Map<keyof TwisterDatabaseEntry, string>

const GUN_LIST = Object.keys(DB_WINDOWS)
GUN_LIST.sort()

export const DatabaseWindow: React.FC<{
  onClose(): void
}> = ({ onClose }) => {
  const [gun, setGun] = useState<DBWindowListType>('Autococker')
  const [colorway, setColorway] = useState('All')

  const handleGunChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setGun(event.target.value)
    setColorway('All')
  }

  const handleColorwayChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setColorway(event.target.value)
  }

  const { database, fieldMap } = DB_WINDOWS[gun]

  const colorwayList = useMemo(() => generateColorwayList(database), [database])

  const filtered = useMemo(() => {
    if (colorway === 'All') {
      return database
    }

    return database.filter((e) => {
      return e.colorway === colorway
    })
  }, [colorway, database])

  // @ts-ignore
  return (
    <RegularModal
      icon={<TwisterIcon />}
      // @ts-ignore
      title={<>TWSTR &nbsp; DB: &nbsp; {gun}</>}
      closeModal={onClose}
    >
      <FilterContainer>
        Model:{' '}
        <Dropdown
          options={GUN_LIST}
          onChange={handleGunChange}
          defaultValue={gun}
        />
      </FilterContainer>
      <FilterContainer>
        Colorway:{' '}
        <Dropdown
          options={['All', ...colorwayList]}
          onChange={handleColorwayChange}
          defaultValue={colorway}
        />
      </FilterContainer>
      <WindowFrame bg="white" boxShadow="in">
        <DataTableContainer>
          <DataTable border={1}>
            <thead>
              <tr>
                {Array.from(fieldMap.entries()).map(([_, f]) => (
                  <th key={f}>{f}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filtered.map((gun, index) => (
                <tr key={index}>
                  {Array.from(fieldMap.entries()).map(([f, _]) => (
                    <td key={f}>{gun[f]}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </DataTable>
        </DataTableContainer>
      </WindowFrame>
    </RegularModal>
  )
}
