import styled from 'styled-components'

export const TwisterImg = styled.img`
  height: 32px;
  width: 32px;
`

export const TwisterIcon = () => {
  return <TwisterImg src="/assets/images/twstr.png" />
}
