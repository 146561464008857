import React, { useCallback, useState } from 'react'
import { Frame } from '@react95/core/dist'
import { Explorer108, Files } from '@react95/icons'
import { DesktopIcon } from './DesktopIcon'
import styled from 'styled-components'
import { IconLabel } from './IconLabel'
import { RegularModal } from '../RegularModal'

const FolderDiv = styled.div`
  display: flex;
  grid-template-columns: repeat(4, 1fr);
  flex-wrap: wrap;
`

const MyFrame = styled(Frame)`
  height: 100%;
`

export const RecycleBin: React.FC<{ onClose(): void }> = ({ onClose }) => {
  return (
    // @ts-ignore
    <RegularModal
      icon={<Explorer108 variant="32x32_4" />}
      title="Recycle Bin"
      closeModal={onClose}
      width="300"
      height="200"
      menu={[
        // @ts-ignore
        { name: 'File', list: [] },
        // @ts-ignore
        { name: 'Edit', list: [] },
        // @ts-ignore
        { name: 'Help', list: [] }
      ]}
    >
      <MyFrame bg="white" boxShadow="in">
        <FolderDiv>
          <DesktopIcon>
            <Files variant="32x32_4" />
            <div>foss.txt</div>
          </DesktopIcon>

          <DesktopIcon>
            <Files variant="32x32_4" />
            <div>g.prj</div>
          </DesktopIcon>
        </FolderDiv>
      </MyFrame>
    </RegularModal>
  )
}

export const RecycleBinContainer: React.FC = () => {
  const [isRecycleBinOpen, setIsRecycleBinOpen] = useState<boolean>(false)
  const handleCloseRecycleBin = useCallback(
    () => setIsRecycleBinOpen(false),
    [setIsRecycleBinOpen]
  )

  return (
    <>
      <DesktopIcon onClick={() => setIsRecycleBinOpen(true)}>
        <Explorer108 variant="32x32_4" />
        <IconLabel>Recycle Bin</IconLabel>
      </DesktopIcon>
      {isRecycleBinOpen && <RecycleBin onClose={handleCloseRecycleBin} />}
    </>
  )
}
