import React from 'react'
import { List } from '@react95/core/dist'
import {
  Computer3,
  FileFind,
  FolderExe2,
  FolderFile,
  FolderPrint,
  FolderSettings,
  HelpBook,
  LoaderBat,
  Settings,
  Winmine1
} from '@react95/icons'
import { TwisterIcon } from './TwisterIcon'

export const StartMenu: React.FC<{
  onOpen: (w: string) => void
}> = ({ onOpen }) => {
  return (
    <List>
      <List.Item icon={<FolderExe2 variant="32x32_4" />}>
        <List>
          <List.Item icon={<TwisterIcon />} onClick={() => onOpen('DB')}>
            TWSTR &nbsp; DB
          </List.Item>
          <List.Item
            icon={<Winmine1 variant="32x32_4" />}
            onClick={() => onOpen('Minesweeper')}
          >
            Minesweeper
          </List.Item>
        </List>
        Programs
      </List.Item>
      <List.Item icon={<FolderFile variant="32x32_4" />}>Documents</List.Item>
      <List.Item icon={<Settings variant="32x32_4" />}>
        <List>
          <List.Item icon={<FolderSettings variant="16x16_4" />}>
            Control Panel
          </List.Item>
          <List.Item icon={<FolderPrint variant="16x16_4" />}>
            Printers
          </List.Item>
        </List>
        Settings
      </List.Item>
      <List.Item icon={<FileFind variant="32x32_4" />}>Find</List.Item>
      <List.Item icon={<HelpBook variant="32x32_4" />}>Help</List.Item>
      <List.Item icon={<LoaderBat variant="32x32_4" />}>Run...</List.Item>
      <List.Divider />
      <List.Item icon={<Computer3 variant="32x32_4" />}>Shut Down...</List.Item>
    </List>
  )
}
