import React, { useRef } from 'react'

import './App.css'
import { GlobalStyle, TaskBar, ThemeProvider } from '@react95/core/dist'
import '@react95/icons/icons.css'

import { StartMenu } from './StartMenu'

import { DesktopView } from './desktop/DesktopView'
import {
  useWindowManager,
  WindowManagerProvider
} from './WindowManagerProvider'
import { Windows } from './Windows'

function TaskBarView() {
  const { toggleWindow } = useWindowManager()

  const startRef = useRef(null)

  return (
    <TaskBar
      ref={startRef}
      list={
        <StartMenu onOpen={(windowName) => toggleWindow(windowName, true)} />
      }
    />
  )
}

export default function App() {
  return (
    <ThemeProvider>
      <GlobalStyle />
      <WindowManagerProvider>
        <TaskBarView />
        <DesktopView />
        <Windows />
      </WindowManagerProvider>
    </ThemeProvider>
  )
}
